@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-btn-danger {
  background-color: #d9534f !important;
  color: white !important;
  border-color: #d9534f !important;
}

.ant-btn-primary {
  background-color: green !important;
  border-color: green !important;
}

.ant-pagination-item-active {
  border-color: #c45628 !important;
}

li.ant-pagination-item-active a {
  color: #c45628 !important;
}

.body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* .ant-form-item-explain-error {
  height: 0px !important;
} */
