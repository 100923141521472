@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

.quickee-page {
  font-family: "Poppins", sans-serif;
  padding: 0;
  background-color: #f7f7f7;
}

/* Hero Section */
.hero-section {
  background-color: #c45628;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/quickee.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.hero-content {
  color: white;
  text-align: center;
  max-width: 700px;
  animation: fadeIn 2s ease-in-out;
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-content p {
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 30px;
}

.cta-button {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background-color: #fff;
  color: #c45628;
  font-weight: 600;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-left: 23%;
}

.cta-button img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-right: 10px; /* Space between icon and text */
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.text {
  color: white;
}

/* About, Quild Tech, etc. Sections */
.about-section,
.quild-tech-section,
.partnerships-section,
.team-section,
.schools-section {
  margin: 60px 0;
  padding: 50px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2.8rem;
  color: #c45628;
  margin-bottom: 30px;
  position: relative;
}

h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 4px;
  background-color: #c45628;
}

p {
  font-size: 1.3rem;
  color: #333;
  line-height: 1.8;
}

/* Grid Sections */
.partners-grid,
.team-grid,
.schools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.partner-card,
.team-card,
.school-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  position: relative;
}

.partner-card:hover,
.team-card:hover,
.school-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.partner-card img,
.team-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.team-card h3 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 10px 0;
}

.team-card p {
  font-size: 1rem;
  color: #777;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.problems-section {
  margin: 60px 0;
  padding: 50px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.problems-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.problem-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.problem-item img {
  width: 60px; /* Adjusted size */
  height: 60px; /* Ensures the image is square */
  object-fit: cover; /* Keeps the image proportional */
  border-radius: 50%; /* Makes the image a circle */
  border: 2px solid #ddd; /* Optional: add a subtle border */
  transition: transform 0.3s ease;
}

.problem-item:hover img {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.problem-item p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
}

.problem-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Footer Section */
.footer {
  background-color: #c45628;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer p {
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
}

.video-iframe-container {
  margin-top: 40px;
  text-align: center;
}

.video-iframe-container h3 {
  font-size: 1.8rem;
  color: #c45628;
  margin-bottom: 20px;
}

.video-iframe-container iframe {
  width: 100%;
  max-width: 800px;
  height: 400px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.iframe-video {
  align-items: center;
  margin-left: 270px;
}

.img2 {
  margin-left: 40%;
}