.reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-left: 15%;
}

.review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 10px;
  width: 60rem;
  max-width: 60rem;
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.user-details {
  display: flex;
  flex-direction: column;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.username {
  font-weight: bold;
}

.date {
  font-size: 0.8rem;
  color: #888;
}

.review-content {
  margin-top: 10px;
  margin-left: 5.5%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  word-break: break-all;
}

.review-actions {
  margin-left: 5%;
  display: flex;
  align-items: center;
}

.review-actions .dislike-count,
.review-actions .like-count {
  font-size: 24px;
  color: #555;
  margin-right: 78px;
}

.review-actions .like-button,
.review-actions .dislike-button {
  font-size: 24px;
  color: #555;
  margin-right: 16px;
  cursor: pointer;
}

.review-actions .like-button:hover,
.review-actions .dislike-button:hover {
  color: #c45628;
}

.add-comment {
  position: fixed;
  bottom: 12%;
  right: 10px;
  z-index: 1000;
  transition: transform 0.3s ease;
  transform: translateY(100%);
}

.add-comment.active {
  transform: translateY(0);
}

.comment-input {
  background-color: #f2f2f2;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.comment-input input {
  margin-right: 1rem;
}

.comment-input button {
  padding: 10px 17px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.comment-input button:hover {
  background-color: green;
}

.add-comment-button {
    background-color: #c45628;
    color: #f2f2f2;
    border-radius: 5px;
    width: 12rem;
    height: 2rem;
    border-color: #c45628;
    cursor: pointer;
    margin-left: 250px;
}

.disabled-button {
  background-color: #ccc;
  color: #999;
}