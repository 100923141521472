.Home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
}
.Header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem 0.5rem 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.Footer {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.SideandDisplay {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}
.social-icons {
  display: flex;
  gap: 20px;
}
.social-icon:hover {
  color: #000;
  transition: color 0.3s ease;
}
.custom-menu {
  background-color: white;
}
.PageContent {
  padding-left: 12px;
}

.header-title {
  color: #fff;
  text-align: center;
}

.Footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  /* width: 100vw !important; */
  z-index: 999;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
}

.contact-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* margin-right: 30rem; */
  width: 80vw !important;
  /* border: 1px solid orange; */
}

.contact-info a {
  margin-bottom: 0rem;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icon {
  text-decoration: none;
  color: #555;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #000;
}

.action-count {
  display: inline-block;
  margin-right: 8px;
  font-size: 16px;
  color: #555;
}
